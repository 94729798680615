import * as React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="py-48 text-center">
        <span className="mb-8 text-4xl font-bold md:text-9xl">404</span>
        <h1 className="font-bold uppercase">page not found</h1>
        <p className="mt-8">
          This page does not seem to exist. <br />
          Return to the{" "}
          <Link
            className="relative mr-4 h-max w-fit py-4 font-bold text-orange-500 after:absolute after:left-0 after:bottom-[20%] after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-white after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100 md:py-6"
            to="/"
          >
            Home page
          </Link>
          instead.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Page Not found</title>
